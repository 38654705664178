import React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeroSection from "./components/hero"
import SkillsSection from "./components/skills"
import ProjectsSection from "./components/projects"

type DataProps = {}

const IndexPage: React.FC<PageProps<DataProps>> = () => {
  return (
    <Layout>     
      <SEO 
        title="Get to Know Me" 
      />
      
      <HeroSection />
      <SkillsSection />
      <ProjectsSection />
    </Layout>
  )
}

export default IndexPage