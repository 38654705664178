import React from 'react'
import { Link } from "gatsby"

type Project = {
  id: number,
  name: string,
  image: string,
}

const projects: Project[] = [
  {id: 0, name: 'JavaScript', icon: 'hi'},  
];

const ProjectsSection: React.FC = () => {
  return (    
    <section className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-2xl">
          <h2 className="text-3xl lg:text-5xl leading-9 font-extrabold text-gray-800 font-display">
            My Work
          </h2>
          <p className="mt-5 lg:mt-10 text-lg leading-7 text-gray-500 font-body">
            Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
          </p>
        </div>

        <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {new Array(3).fill(0).map((el, index) => (
            <div>
              <Link to="/project" className="group">
                <div className="relative pb-2/3 overflow-hidden rounded-sm shadow-sm cursor-pointer">
                  {index === 0
                    ? <img className="absolute w-full h-full object-cover transform hover:opacity-85 hover:scale-110 transition duration-300" src="https://cdn.sanity.io/images/3do82whm/next/98207d99e70275cc8188a4124875c2c0f3c4e034-1600x900.jpg?rect=0,50,1600,800&w=800&h=400&fit=clip&auto=format" alt=""/>
                    : index === 1
                    ? <img className="absolute w-full h-full object-cover transform hover:opacity-85 hover:scale-110 transition duration-300" src="https://cdn.sanity.io/images/3do82whm/next/85a1ec7a7892721d2160aee938cd00941806997d-3800x2690.jpg?rect=0,395,3800,1900&w=640&h=320&fit=clip&auto=format" alt=""/>
                    : <img className="absolute w-full h-full object-cover transform hover:opacity-85 hover:scale-110 transition duration-300" src="https://cdn.sanity.io/images/3do82whm/next/99cd8f94e38b5c6220b132334a26080d646932cf-980x549.jpg?rect=0,29,980,490&w=640&h=320&fit=clip&auto=format" alt=""/>
                  }
                </div>            
                <a href="#" className="block">
                  <h3 className="mt-4 text-xl lg:text-2xl leading-7 font-semibold text-gray-900 font-display">
                    Boost your conversion rate
                  </h3>
                  <p className="mt-3 text-base leading-6 text-gray-500 font-body">
                    Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.
                  </p>
                </a>
              </Link>

              <div className="mt-6 flex items-center">
                <a className="font-body cursor-pointer text-red-600 transform hover:translate-x-1 transition duration-150 ease-out" href="">
                  View details &rarr;                               
                </a>              
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default ProjectsSection