import React from 'react'

import JavaScriptIcon from "../../../icons/javascript.svg"
import HTMLIcon from "../../../icons/html5.svg"
import CSSIcon from "../../../icons/css3.svg"
import SassIcon from "../../../icons/sass.svg"
import TypeScriptIcon from "../../../icons/typescript.svg"
import ReactIcon from "../../../icons/react.svg"
import ReduxIcon from "../../../icons/redux.svg"
import NodeJsIcon from "../../../icons/node-dot-js.svg"

type Skill = {
  id: number,
  name: string,
  icon: string,
}

const skills: Skill[] = [
  {id: 0, name: 'JavaScript', icon: JavaScriptIcon},
  {id: 1, name: 'HTML', icon: HTMLIcon},
  {id: 2, name: 'CSS', icon: CSSIcon},
  {id: 3, name: 'Sass', icon: SassIcon},
  {id: 4, name: 'TypeScript', icon: TypeScriptIcon},
  {id: 5, name: 'React', icon: ReactIcon},
  {id: 6, name: 'Redux', icon: ReduxIcon},
  {id: 7, name: 'Node.js', icon: NodeJsIcon},
];

const SkillsSection: React.FC = () => {
  return (    
    <section className="bg-white">
      <div className="max-w-screen-xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-2xl">
          <h2 className="text-3xl lg:text-5xl leading-9 font-extrabold text-gray-800 font-display">
            My Skills
          </h2>
          <p className="mt-5 lg:mt-10 text-lg leading-7 text-gray-500 font-body">
            Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla nec.
          </p>
        </div>

        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {skills.map(skill => {            
            return (
              <div key={skill.id} className="flex space-x-3">
                <div className="flex-shrink-0 h-6 w-6 text-green-500">
                  <img className="opacity-85" src={skill.icon} alt={`${skill.name} icon`}/>                  
                </div>
                <div className="space-y-4">
                  <dt className="text-lg leading-6 font-medium text-gray-800 font-display">
                    {skill.name}
                  </dt>
                  <dd className="flex space-x-3 lg:py-0 lg:pb-4">
                    <span className="text-base leading-6 text-gray-500 font-body">
                      Tempor tellus in aliquet eu et sit nulla tellus. Suspendisse est, molestie blandit quis ac. Lacus.
                    </span>
                  </dd>
                </div>
              </div>
            )
          })}
        </dl>
      </div>
    </section>
  )
}

export default SkillsSection
